<template>
	<div class="content">
		<!-- Main Information -->
		<div class="lineform">
			<span class="title">Main Information</span>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col left">Vessel</div>
					<div class="tableHeader content-col must">Name</div>
				</el-col>
				<el-col :span="5">
					<el-input v-model="form.vesselName"
						:placeholder="form.vesselName?.toString() !== '' ? '' : errorInputTip"
						:class="form.vesselName?.toString() !== '' ? '' : 'errorInput'"></el-input>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col must">IMO Num</div>
				</el-col>
				<el-col :span="5">
					<el-input v-model="form.imoNum" :placeholder="form.imoNum?.toString() !== '' ? '' : errorInputTip"
						:class="form.imoNum?.toString() !== '' ? '' : 'errorInput'"></el-input>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col must">Call Sign</div>
				</el-col>
				<el-col :span="5">
					<el-input v-model="form.callSign" :placeholder="form.callSign?.toString() !== '' ? '' : errorInputTip"
						:class="form.callSign?.toString() !== '' ? '' : 'errorInput'"></el-input>
				</el-col>
			</el-row>
		</div>

		<!-- Position and Time Information -->
		<div class="lineform">
			<span class="title">Position and Time Information</span>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col">Position</div>
				</el-col>
				<el-col :span="8">
					<div class="formContent">
						<div class="tableHeader content-col left must">Latitude</div>
						<el-input class="lonLat" v-model="form.positionLatitudeLeft" placeholder=""
							:class="form.positionLatitudeLeft?.toString() !== '' ? '' : 'errorInput'"></el-input>°
						<el-input class="lonLat" v-model="form.positionLatitudeCenter" placeholder=""
							:class="form.positionLatitudeCenter?.toString() !== '' ? '' : 'errorInput'"></el-input>′
						<el-select class="lonLat" v-model="form.positionLatitudeRight" placeholder=""
							:class="form.positionLatitudeRight?.toString() !== '' ? '' : 'errorInput'">
							<el-option v-for="data in NS" :label="data" :value="data" :key="data" />
						</el-select>
					</div>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col must">Longitude</div>
				</el-col>
				<el-col :span="8">
					<div class="formContent">
						<el-input class="lonLat" v-model="form.longitudeLeft" placeholder=""
							:class="form.longitudeLeft?.toString() !== '' ? '' : 'errorInput'"></el-input>°
						<el-input class="lonLat" v-model="form.longitudeCenter" placeholder=""
							:class="form.longitudeCenter?.toString() !== '' ? '' : 'errorInput'"></el-input>′
						<el-select class="lonLat" v-model="form.longitudeRight" placeholder=""
							:class="form.longitudeRight?.toString() !== '' ? '' : 'errorInput'">
							<el-option v-for="data in EW" :label="data" :value="data" :key="data" />
						</el-select>
					</div>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col must">Position Date</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<mydatePicker type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
							v-model="form.positionDate"
							:placeholder="form.positionDate?.toString() !== '' ? '' : errorInputTip"
							:class="form.positionDate?.toString() !== '' ? '' : 'errorInput'"></mydatePicker>
						<div class="unit">UTC</div>
					</div>
				</el-col>
			</el-row>
		</div>

		<!-- Voyage Information -->
		<div class="lineform">
			<span class="title">Voyage Information</span>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col">Fore Draft</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.foreDraft" placeholder=""></el-input>
						<div class="unit">M</div>
					</div>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col">Aft Draft</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.aftDraft" placeholder=""></el-input>
						<div class="unit">M</div>
					</div>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col">Mid Draft</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.midDraft" placeholder=""></el-input>
						<div class="unit">M</div>
					</div>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="6">
					<div class="tableHeader content-col must">Avg Speed Over Ground SLR</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.avgSpeedOverGroundSlr"
							:placeholder="form.avgSpeedOverGroundSlr?.toString() !== '' ? '' : errorInputTip"
							:class="form.avgSpeedOverGroundSlr?.toString() !== '' ? '' : 'errorInput'"></el-input>
						<div class="unit">KTS</div>
					</div>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col must">Course</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.course" :placeholder="form.course?.toString() !== '' ? '' : errorInputTip"
							:class="form.course?.toString() !== '' ? '' : 'errorInput'"></el-input>
						<div class="unit">DEG</div>
					</div>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col must">Distance SLR</div>
				</el-col>
				<el-col :span="5">
					<el-input v-model="form.distanceSlr"
						:placeholder="form.distanceSlr?.toString() !== '' ? '' : errorInputTip"
						:class="form.distanceSlr?.toString() !== '' ? '' : 'errorInput'"></el-input>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col must">Distance To Go</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.distanceToGo"
							:placeholder="form.distanceToGo?.toString() !== '' ? '' : errorInputTip"
							:class="form.distanceToGo?.toString() !== '' ? '' : 'errorInput'"></el-input>
						<div class="unit">NM</div>
					</div>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col">ME Power SLR</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.mePowerSlr" placeholder=""></el-input>
						<div class="unit">KW</div>
					</div>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col">Avg RPM SLR</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.avgRpmSlr" placeholder=""></el-input>
						<div class="unit">RPM</div>
					</div>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col">Slip SLR</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.slipSlr" placeholder=""></el-input>
						<div class="unit">%</div>
					</div>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col must">Time SLR</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.timeSlr" :placeholder="form.timeSlr?.toString() !== '' ? '' : errorInputTip"
							:class="form.timeSlr?.toString() !== '' ? '' : 'errorInput'"></el-input>
						<div class="unit">HRS</div>
					</div>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col">MCR</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.mcr" placeholder=""></el-input>
						<div class="unit">%</div>
					</div>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col">Ordered Speed</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.orderedSpeed" placeholder=""></el-input>
						<div class="unit">KTS</div>
					</div>
				</el-col>
			</el-row>
			<el-row class="grey-line"></el-row>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col">Wind Beaufort</div>
				</el-col>
				<el-col :span="5">
					<el-input v-model="form.windBeaufort" placeholder=""></el-input>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col">Wind Direction</div>
				</el-col>
				<el-col :span="5">
					<el-select class="lonLat" v-model="form.windDirection" placeholder="">
						<el-option v-for="data in EWNS" :label="data" :value="data" :key="data" />
					</el-select>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col">Seas Height</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.seasHeight" placeholder=""></el-input>
						<div class="unit">M</div>
					</div>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col">Swell Height</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input v-model="form.swellHeight" placeholder=""></el-input>
						<div class="unit">M</div>
					</div>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col">Swell Direction</div>
				</el-col>
				<el-col :span="5">
					<el-select class="lonLat" v-model="form.swellDirection" placeholder="">
						<el-option v-for="data in EWNS" :label="data" :value="data" :key="data" />
					</el-select>
				</el-col>
			</el-row>
		</div>
		<!-- Fuel -->
		<div class="lineform">
			<span class="title">Fuel</span>
			<el-row class="line-row">
				<el-col :span="4">
					<div class="tableHeader content-col">(MT)</div>
				</el-col>
				<el-col :span="5">
					<div class="tableHeader content-col">HFO</div>
				</el-col>
				<el-col :span="5">
					<div class="tableHeader content-col">LSFO</div>
				</el-col>
				<el-col :span="5">
					<div class="tableHeader content-col">MDO</div>
				</el-col>
				<el-col :span="5">
					<div class="tableHeader content-col">MGO</div>
				</el-col>
			</el-row>
			<el-row v-for="(data, index) in fuelData" :key="index" class="line-row">
				<el-col :span="4">
					<div class="content-col" :class="data.must ? 'must' : ''">{{ data.MT }}</div>
				</el-col>
				<el-col :span="5" v-for="(value, key) in data" :key="key" v-show="!['MT', 'type', 'must'].includes(key)">
					<el-input v-model="form[data[key]]" v-if="data['type'] === 'input'" @change="setData()"
						:placeholder="!form[data[key]] && data.must && form[data[key]] !== 0 ? errorInputTip : ''"
						:class="!form[data[key]] && data.must && form[data[key]] !== 0 ? 'errorInput' : ''"></el-input>
					<el-select v-model="form[data[key]]" v-else placeholder="">
						<el-option v-for="data in fuelList" :label="data.label" :value="data.value" :key="data.value" />
					</el-select>
				</el-col>
			</el-row>
		</div>
		<!-- Main Information -->
		<div class="lineform">
			<span class="title">Next Port</span>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col">Next Port</div>
				</el-col>
				<el-col :span="5">
					<el-input v-model="form.nextPortNextPort" placeholder=""></el-input>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col">UNLOCODE</div>
				</el-col>
				<el-col :span="5">
					<el-input v-model="form.nextPortUnlocode" placeholder=""></el-input>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col">ETA</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<mydatePicker type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
							v-model="form.nextPortEta"></mydatePicker>
						<div class="unit">UTC</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
import { parse } from 'uuid';

export default {
	props: {
		textForm: {
			type: Object,
			default: () => { },
		},
	},
	watch: {
		textForm: {
			handler() {
				this.setData('1');
			},
		},
	},
	data() {
		return {
			errorInputTip: 'Field is empty!',
			form: {
				vesselName: '',
				imoNum: '',
				callSign: '',
				positionLatitudeLeft: '',
				positionLatitudeCenter: '',
				positionLatitudeRight: '',
				longitudeLeft: '',
				longitudeCenter: '',
				longitudeRight: '',
				positionDate: '',
				foreDraft: '',
				aftDraft: '',
				midDraft: '',
				avgSpeedOverGroundSlr: '',
				course: '',
				distanceSlr: '',
				distanceToGo: '',
				mePowerSlr: '',
				avgRpmSlr: '',
				slipSlr: '',
				timeSlr: '',
				mcr: '',
				orderedSpeed: '',
				windBeaufort: '',
				windDirection: '',
				seasHeight: '',
				swellHeight: '',
				swellDirection: '',
				brobHfo: 0,
				brobLsfo: 0,
				brobMdo: 0,
				brobMgo: 0,
				meConsSlrHfo: 0,
				meConsSlrLsfo: 0,
				meConsSlrMdo: 0,
				meConsSlrMgo: 0,
				aeConsSlrHfo: 0,
				aeConsSlrLsfo: 0,
				aeConsSlrMdo: 0,
				aeConsSlrMgo: 0,
				otherConsSlrHfo: 0,
				otherConsSlrLsfo: 0,
				otherConsSlrMdo: 0,
				otherConsSlrMgo: 0,
				totalConsSlrHfo: 0,
				totalConsSlrLsfo: 0,
				totalConsSlrMdo: 0,
				totalConsSlrMgo: 0,
				nextPortNextPort: '',
				nextPortUnlocode: '',
				nextPortEta: '',
			},
			fuelList: [
				{
					label: 'other',
					value: 'other',
				},
				{
					label: 'DMX',
					value: 'DMX',
				},
				{
					label: 'DMA',
					value: 'DMA',
				},
				{
					label: 'DMZ',
					value: 'DMZ',
				},
				{
					label: 'DMB',
					value: 'DMB',
				},
				{
					label: 'DMC',
					value: 'DMC',
				},
				{
					label: 'RMA10',
					value: 'RMA10',
				},
				{
					label: 'RMB30',
					value: 'RMB30',
				},
				{
					label: 'RMD50',
					value: 'RMD50',
				},
				{
					label: 'RME180',
					value: 'RME180',
				},
				{
					label: 'RMG180',
					value: 'RMG180',
				},
				{
					label: 'RMG380',
					value: 'RMG380',
				},
				{
					label: 'RMG500',
					value: 'RMG500',
				},
				{
					label: 'RMG700',
					value: 'RMG700',
				},
			],
			NS: ['N', 'S'],
			EW: ['E', 'W'],
			EWNS: ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'],
			//
			fuelData: [
				{
					MT: 'BROB',
					HFO: 'brobHfo',
					LSFO: 'brobLsfo',
					MDO: 'brobMdo',
					MGO: 'brobMgo',
					type: 'input',
					must: true,
				},
				{
					MT: 'ME Cons SLR',
					HFO: 'meConsSlrHfo',
					LSFO: 'meConsSlrLsfo',
					MDO: 'meConsSlrMdo',
					MGO: 'meConsSlrMgo',
					type: 'input',
				},
				{
					MT: 'AE Cons SLR',
					HFO: 'aeConsSlrHfo',
					LSFO: 'aeConsSlrLsfo',
					MDO: 'aeConsSlrMdo',
					MGO: 'aeConsSlrMgo',
					type: 'input',
				},
				{
					MT: 'Other Cons SLR',
					HFO: 'otherConsSlrHfo',
					LSFO: 'otherConsSlrLsfo',
					MDO: 'otherConsSlrMdo',
					MGO: 'otherConsSlrMgo',
					type: 'input',
				},
				{
					MT: 'Total Cons SLR',
					HFO: 'totalConsSlrHfo',
					LSFO: 'totalConsSlrLsfo',
					MDO: 'totalConsSlrMdo',
					MGO: 'totalConsSlrMgo',
					type: 'input',
				},
			],
		};
	},
	methods: {
		/**
		 *
		 * @param {*} type  1 左侧文字匹配 || null 输入框计算逻辑
		 */
		setData(type) {
			if (type === '1') {
				this.form = {
					...this.form,
					...this.textForm,
				};
			}
			this.fuelData.forEach(filedList => {
				for (let key in filedList) {
					if (!['MT', 'type', 'must'].includes(key)) {
						if (!this.form[filedList[key]]) {
							this.form[filedList[key]] = 0
						}
						if (typeof this.form[filedList[key]] !== 'number') {
							const matches = this.form[filedList[key]].match(/\d+(\.\d+)?/);
							this.form[filedList[key]] = matches ? matches[0].toString() + '' : 0;
						}
					}
				}
			})
			// 求和
			let typeList = ['Hfo', 'Lsfo', 'Mdo', 'Mgo'];
			let needSumList = ['otherConsSlr', 'meConsSlr', 'aeConsSlr'];
			/**
			 * 精确加法
			 */
			function add(num1, num2) {
				console.log("🚀 ~ add ~ num1, num2:", num1, num2)
				const num1Digits = (num1.toString().split('.')[1] || '').length;
				const num2Digits = (num2.toString().split('.')[1] || '').length;
				const baseNum = Math.pow(10, Math.max(num1Digits, num2Digits));
				return (num1 * baseNum + num2 * baseNum) / baseNum;
			}
			typeList.forEach((e) => {
				let sum = 0;
				needSumList.forEach((val) => {
					sum = add(sum, this.form[val + e])
				});
				this.form['totalConsSlr' + e] = parseInt(sum * 1000000) / 1000000;
			});
		},
		clear() {
			for (let key in this.form) {
				this.form[key] = '';
			}
		},
	},
};
</script>

<style lang="less" scoped>
.content {
	width: 100%;
	height: 100%;

	.lineform {
		width: 100%;
		margin-bottom: 15px;

		.title {
			font-size: 12px;
		}

		.grey {
			background: rgb(245, 245, 245);
			font-size: 0.875rem;
			color: #606266;
		}

		::v-deep .errorInput {
			border-color: #f56c6c !important;

			.el-input__inner {
				border-color: #f56c6c !important;
			}
		}

		// .grey::before{
		// 	content: '*';
		// 	color: #F56C6C;
		// 	margin-right: 0.25rem;
		// }
		::v-deep .form {
			margin-top: 5px;
			border: 1px solid rgba(210, 218, 228, 1);
			display: flex;

			.line {
				width: 1px;
				height: 100%;
				border: 1px solid rgba(210, 218, 228, 1);
			}

			.only {
				.el-form-item__label {
					width: 140px;
				}
			}

			.el-form-item {
				margin: 0;
				display: flex;

				.el-form-item__label {
					display: block;
					background: rgb(245, 245, 245);
					padding-left: 5px;
				}

				.el-form-item__content {
					height: 40px;
				}
			}
		}

		.line-row {
			border: 1px solid rgba(210, 218, 228, 1);
		}

		.content-col {
			height: 40px;
			line-height: 40px;
			border: 1px solid rgba(210, 218, 228, 1);
		}

		.must::after {
			content: '*';
			color: #f56c6c;
			margin-right: 0.25rem;
		}

		.left {
			float: left;
			padding: 0 5px;
		}

		.header {
			background: rgb(245, 245, 245);
			text-align: left;
			padding-left: 5px;
		}

		.tableHeader {
			background: rgb(245, 245, 245);
			text-align: center;
		}

		.formContent {
			display: flex;
			align-items: center;

			.lonLat {
				width: 100px;
			}

			.unit {
				margin: 0 5px;
				width: 30px;
				text-align: center;
			}
		}

		.grey-line {
			border: 1px solid rgba(210, 218, 228, 1);
			background: rgb(245, 245, 245);
			height: 22px;
			width: 100%;
		}
	}
}
</style>
